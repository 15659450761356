import { Constant } from '../../model/Constant'
import './Footer.scss'

const Footer = ({ name: Name, shopAdd, shopPhone, shopEmail, shopWhatsappNo }: Constant) => {
  return (
    /* <!-- ======= Footer ======= --> */
    <footer id="footer" className="footer" >
      <div className="container">
        <div className="row gy-3">
          <div className="col-lg-3 col-md-6 d-flex">
            <i className="bi bi-geo-alt icon"></i>
            <div>
              <h4>Address</h4>
              <p>
                {shopAdd}
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links d-flex">
            <i className="bi bi-telephone icon"></i>
            <div>
              <h4>Reservations</h4>
              <p>
                <strong>Phone:</strong> {shopPhone};<br />
                <strong>Email:</strong> {shopEmail}<br />
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links d-flex">
            <i className="bi bi-clock icon"></i>
            <div>
              <h4>Opening Hours</h4>
              <p>
                <strong>Mon-Sat:</strong> 11AM - 23PM<br />
                <strong>Sunday:</strong> Closed
              </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Follow Us</h4>
            <div className="social-links d-flex">
              {/* <button className="twitter"><i className="bi bi-twitter"></i> </button> */}
              <a href='https://www.facebook.com/superart.surat' target='_blank' className="facebook"><i className="bi bi-facebook"></i> </a>
              <a href='https://www.instagram.com/super_art_surat/' target='_blank' className="instagram"><i className="bi bi-instagram"></i> </a>
              {/* <button className="linkedin"><i className="bi bi-linkedin"></i> </button> */}
            </div>
          </div>

        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>{Name}</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          {/* <!-- All the links in the footer should remain intact. -->
                <!-- You can delete the links only if you purchased the pro version. -->
                <!-- Licensing information: https://bootstrapmade.com/license/ -->
                <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/yummy-bootstrap-restaurant-website-template/ --> */}
          {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
        </div>
      </div>
    </footer>
    /* <!-- End Footer --> */
  )
}

export default Footer