import { Constant } from '../../model/Constant'
import './MainContainer.scss'

const MainContainer = ({ name, shopAdd, shopEmail, shopPhone, shopWhatsappNo }: Constant) => {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero" className="hero d-flex align-items-center section-bg">
        <div className="container">
          <div className="row justify-content-between gy-5">
            <div className="col-lg-7 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
              <h2 data-aos="fade-up">Work as Like as name...</h2>
              <p data-aos="fade-up" data-aos-delay="100">
                <strong>Looking for that perfect corporate & Personalize gift?</strong>
                <br />
                We have the answer for you. You don't need to go anywhere, Except <strong>{name}.</strong>
              </p>
              <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                <a href="#book-a-table" className="btn-book-a-table">
                  <i className="bi bi-whatsapp"></i>
                  <span className='d-none d-sm-inline-block ps-1'>Talk with us</span>
                </a>
                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 text-center text-lg-start">
              <img src="/assets/img/hero-img.png" className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="300" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero Section --> */}

      <main id="main">

        {/* <!-- ======= About Section ======= --> */}
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <h2>About Us</h2>
              <p>Learn More <span>About Us</span></p>
            </div>

            <div className="row gy-4">
              <div className="col-lg-7 position-relative about-img" style={{ backgroundImage: 'url(/assets/img/about.jpg)' }} data-aos="fade-up" data-aos-delay="150">
                <div className="call-us position-absolute">
                  <h4>For More Information</h4>
                  <p>{shopPhone}</p>
                </div>
              </div>
              <div className="col-lg-5 d-flex align-items-end" data-aos="fade-up" data-aos-delay="300">
                <div className="content ps-0 ps-lg-5">
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua.
                  </p>
                  <ul>
                    <li><i className="bi bi-check2-all"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                    <li><i className="bi bi-check2-all"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                    <li><i className="bi bi-check2-all"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                  </ul>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
                  </p>

                  <div className="position-relative mt-4">
                    <img src="/assets/img/about-2.jpg" className="img-fluid" alt="" />
                    <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox play-btn">
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
        {/* <!-- End About Section --> */}

        {/* <!-- ======= Why Us Section ======= --> */}
        <section id="why-us" className="why-us section-bg">
          <div className="container" data-aos="fade-up">

            <div className="row gy-4">

              <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                <div className="why-box">
                  <h3>Why Choose Yummy?</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                    Asperiores dolores sed et. Tenetur quia eos. Autem tempore quibusdam vel necessitatibus optio ad corporis.
                  </p>
                  <div className="text-center">
                    <button className="more-btn">Learn More <i className="bx bx-chevron-right"></i></button>
                  </div>
                </div>
              </div>
              {/* <!-- End Why Box --> */}

              <div className="col-lg-8 d-flex align-items-center">
                <div className="row gy-4">

                  <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="bi bi-clipboard-data"></i>
                      <h4>Corporis voluptates officia eiusmod</h4>
                      <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                    </div>
                  </div>
                  {/* <!-- End Icon Box --> */}

                  <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="bi bi-gem"></i>
                      <h4>Ullamco laboris ladore pan</h4>
                      <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                    </div>
                  </div>
                  {/* <!-- End Icon Box --> */}

                  <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                    <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                      <i className="bi bi-inboxes"></i>
                      <h4>Labore consequatur incidid dolore</h4>
                      <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                    </div>
                  </div>
                  {/* <!-- End Icon Box --> */}

                </div>
              </div>

            </div>

          </div>
        </section>
        {/* <!-- End Why Us Section --> */}

        {/* <!-- ======= Stats Counter Section ======= --> */}
        <section id="stats-counter" className="stats-counter">
          <div className="container" data-aos="zoom-out">

            <div className="row gy-4">

              <div className="col-md-6">
                <div className="stats-item text-center w-100 h-100">
                  <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
                  <p>Clients</p>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}

              <div className="col-md-6">
                <div className="stats-item text-center w-100 h-100">
                  <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
                  <p>Products</p>
                </div>
              </div>
              {/* <!-- End Stats Item --> */}
            </div>

          </div>
        </section>
        {/* <!-- End Stats Counter Section --> */}

        {/* <!-- ======= Menu Section ======= --> */}
        <section id="menu" className="menu">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Menu</h2>
              <p>Check Our <span>Top Products</span></p>
            </div>

            <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src="/assets/img/menu/menu-item-1.jpg" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src="/assets/img/menu/menu-item-2.jpg" className="d-block w-100" alt="..." />
                </div>
                <div className="carousel-item">
                  <img src="/assets/img/menu/menu-item-3.jpg" className="d-block w-100" alt="..." />
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </section>{/* <!-- End Menu Section --> */}

        {/* <!-- ======= Testimonials Section ======= --> */}
        <section id="testimonials" className="testimonials section-bg">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <h2>Testimonials</h2>
              <p>What Are They <span>Saying About Us</span></p>
            </div>

            <div className="slides-1 swiper" data-aos="fade-up" data-aos-delay="100">
              <div className="swiper-wrapper">

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="row gy-4 justify-content-center">
                      <div className="col-lg-6">
                        <div className="testimonial-content">
                          <p>
                            <i className="bi bi-quote quote-icon-left"></i>
                            Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                            <i className="bi bi-quote quote-icon-right"></i>
                          </p>
                          <h3>Saul Goodman</h3>
                          <h4>Ceo &amp; Founder</h4>
                          <div className="stars">
                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center">
                        <img src="/assets/img/testimonials/testimonials-1.jpg" className="img-fluid testimonial-img" alt="" />
                      </div>
                    </div>
                  </div>
                </div>{/* <!-- End testimonial item --> */}

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="row gy-4 justify-content-center">
                      <div className="col-lg-6">
                        <div className="testimonial-content">
                          <p>
                            <i className="bi bi-quote quote-icon-left"></i>
                            Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                            <i className="bi bi-quote quote-icon-right"></i>
                          </p>
                          <h3>Sara Wilsson</h3>
                          <h4>Designer</h4>
                          <div className="stars">
                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center">
                        <img src="/assets/img/testimonials/testimonials-2.jpg" className="img-fluid testimonial-img" alt="" />
                      </div>
                    </div>
                  </div>
                </div>{/* <!-- End testimonial item --> */}

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="row gy-4 justify-content-center">
                      <div className="col-lg-6">
                        <div className="testimonial-content">
                          <p>
                            <i className="bi bi-quote quote-icon-left"></i>
                            Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                            <i className="bi bi-quote quote-icon-right"></i>
                          </p>
                          <h3>Jena Karlis</h3>
                          <h4>Store Owner</h4>
                          <div className="stars">
                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center">
                        <img src="/assets/img/testimonials/testimonials-3.jpg" className="img-fluid testimonial-img" alt="" />
                      </div>
                    </div>
                  </div>
                </div>{/* <!-- End testimonial item --> */}

                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <div className="row gy-4 justify-content-center">
                      <div className="col-lg-6">
                        <div className="testimonial-content">
                          <p>
                            <i className="bi bi-quote quote-icon-left"></i>
                            Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                            <i className="bi bi-quote quote-icon-right"></i>
                          </p>
                          <h3>John Larson</h3>
                          <h4>Entrepreneur</h4>
                          <div className="stars">
                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 text-center">
                        <img src="/assets/img/testimonials/testimonials-4.jpg" className="img-fluid testimonial-img" alt="" />
                      </div>
                    </div>
                  </div>
                </div>{/* <!-- End testimonial item --> */}

              </div>
              <div className="swiper-pagination"></div>
            </div>

          </div>
        </section>{/* <!-- End Testimonials Section --> */}

        {/* <!-- ======= Events Section ======= --> */}
        <section id="events" className="events">
          <div className="container-fluid" data-aos="fade-up">

            <div className="section-header">
              <h2>Events</h2>
              <p>Share <span>Your Moments</span> In Our Restaurant</p>
            </div>

            <div className="slides-3 swiper" data-aos="fade-up" data-aos-delay="100">
              <div className="swiper-wrapper">

                <div className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage: "url(/assets/img/events-1.jpg)" }}>
                  <h3>Custom Parties</h3>
                  <div className="price align-self-start">$99</div>
                  <p className="description">
                    Quo corporis voluptas ea ad. Consectetur inventore sapiente ipsum voluptas eos omnis facere. Enim facilis veritatis id est rem repudiandae nulla expedita quas.
                  </p>
                </div>{/* <!-- End Event item --> */}

                <div className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage: "url(/assets/img/events-2.jpg)" }}>
                  <h3>Private Parties</h3>
                  <div className="price align-self-start">$289</div>
                  <p className="description">
                    In delectus sint qui et enim. Et ab repudiandae inventore quaerat doloribus. Facere nemo vero est ut dolores ea assumenda et. Delectus saepe accusamus aspernatur.
                  </p>
                </div>{/* <!-- End Event item --> */}

                <div className="swiper-slide event-item d-flex flex-column justify-content-end" style={{ backgroundImage: "url(/assets/img/events-3.jpg)" }}>
                  <h3>Birthday Parties</h3>
                  <div className="price align-self-start">$499</div>
                  <p className="description">
                    Laborum aperiam atque omnis minus omnis est qui assumenda quos. Quis id sit quibusdam. Esse quisquam ducimus officia ipsum ut quibusdam maxime. Non enim perspiciatis.
                  </p>
                </div>{/* <!-- End Event item --> */}

              </div>
              <div className="swiper-pagination"></div>
            </div>

          </div>
        </section>
        {/* <!-- End Events Section --> */}

        {/* <!-- ======= Gallery Section ======= --> */}
        <section id="gallery" className="gallery section-bg">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <h2>gallery</h2>
              <p>Check <span>Our Gallery</span></p>
            </div>

            <div className="gallery-slider swiper">
              <div className="swiper-wrapper align-items-center">
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-1.jpg"><img src="/assets/img/gallery/gallery-1.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-2.jpg"><img src="/assets/img/gallery/gallery-2.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-3.jpg"><img src="/assets/img/gallery/gallery-3.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-4.jpg"><img src="/assets/img/gallery/gallery-4.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-5.jpg"><img src="/assets/img/gallery/gallery-5.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-6.jpg"><img src="/assets/img/gallery/gallery-6.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-7.jpg"><img src="/assets/img/gallery/gallery-7.jpg" className="img-fluid" alt="" /></a></div>
                <div className="swiper-slide"><a className="glightbox" data-gallery="images-gallery" href="/assets/img/gallery/gallery-8.jpg"><img src="/assets/img/gallery/gallery-8.jpg" className="img-fluid" alt="" /></a></div>
              </div>
              <div className="swiper-pagination"></div>
            </div>

          </div>
        </section>{/* <!-- End Gallery Section --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">

            <div className="section-header">
              <h2>Contact</h2>
              <p>Need Help? <span>Contact Us</span></p>
            </div>

            <div className="mb-3">
              {/* <iframe title='google map load' style={{ border: 0, width: '100%', height: '350px' }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder="0" allowFullScreen></iframe> */}
            </div>{/* <!-- End Google Maps --> */}

            <div className="row gy-4">

              <div className="col-md-6">
                <div className="info-item d-flex align-items-center p-1 p-sm-3">
                  <i className="icon bi bi-map flex-shrink-0"></i>
                  <div>
                    <h3>Our Address</h3>
                    <p>
                      {shopAdd}
                    </p>
                  </div>
                </div>
              </div>{/* <!-- End Info Item --> */}

              <div className="col-md-6">
                <div className="info-item d-flex align-items-center p-1 p-sm-3">
                  <i className="icon bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h3>Email Us</h3>
                    <p>{shopEmail}</p>
                  </div>
                </div>
              </div>{/* <!-- End Info Item --> */}

              <div className="col-md-6">
                <div className="info-item  d-flex align-items-center p-1 p-sm-3">
                  <i className="icon bi bi-telephone flex-shrink-0"></i>
                  <div>
                    <h3>Call Us</h3>
                    <p>{shopPhone}</p>
                  </div>
                </div>
              </div>{/* <!-- End Info Item --> */}

              <div className="col-md-6">
                <div className="info-item  d-flex align-items-center p-1 p-sm-3">
                  <i className="icon bi bi-share flex-shrink-0"></i>
                  <div>
                    <h3>Opening Hours</h3>
                    <div><strong>Mon-Sat:</strong> 11AM - 23PM;
                      <strong> Sunday:</strong> Closed
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Info Item --> */}
            </div>

            <form method="post" className="php-email-form p-3 p-md-4">
              <div className="form-group">
                <textarea className="form-control" name="message" rows={5} placeholder="Message" required></textarea>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
            {/* <!--End Contact Form --> */}

          </div>
        </section>
        {/* <!-- End Contact Section --> */}

      </main>
      {/* <!-- End #main --> */}

    </>
  )
}

export default MainContainer