import './App.scss';
import MasterLayout from './layout/MasterLayout';

function App() {
  const shopName = "SUPER ART";
  const shopAdd = "303, Sunrise Complex, Nr. Savji Korat Bridge, Lajamani chowk, Mota Varachha, Surat, Gujarat - 394101.";
  const shopEmail = "superartsurat@gmail.com"
  const shopPhone = "+91 9512160002"
  const shopWhatsappNo = "+91 9512160002"
  return (
    <div className="App">
      <MasterLayout shopAdd={shopAdd} name={shopName} shopEmail={shopEmail} shopPhone={shopPhone} shopWhatsappNo={shopWhatsappNo} />
    </div>
  );
}

export default App;
