import { Constant } from "../model/Constant";
import { useExternalScript } from "../model/use-external-script";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import MainContainer from "./main-container/MainContainer";

function MasterLayout({ name, shopAdd, shopEmail, shopPhone, shopWhatsappNo }: Constant) {
  useExternalScript('/assets/js/main.js');
  
  return (
    <div>
      <Header></Header>
      <MainContainer shopAdd={shopAdd} name={name} shopEmail={shopEmail} shopPhone={shopPhone} shopWhatsappNo={shopWhatsappNo}></MainContainer>
      <Footer shopAdd={shopAdd} name={name} shopEmail={shopEmail} shopPhone={shopPhone} shopWhatsappNo={shopWhatsappNo}></Footer>
      <button className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i> </button>
      <div id="preloader"></div>
    </div>
  )
}

export default MasterLayout;